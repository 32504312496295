
class StringUtil {

  replaceAll(str: string, find: string, replace: string) {
    return str.replace(/[\(\)\.\s-]+/g, replace)
  }

  unMaskCpf(cpf: string) {
    return cpf.replace(/\D/g, '');
  }

  cpfCpnjMask(cpf: string) {
    cpf = cpf.replace(/\D/g, "")
    if (cpf.length <= 11) {
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    } else {
      cpf = cpf.replace(/^(\d{2})(\d)/, "$1.$2")
      cpf = cpf.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
      cpf = cpf.replace(/\.(\d{3})(\d)/, ".$1/$2")
      cpf = cpf.replace(/(\d{4})(\d)/, "$1-$2")
    }

    return cpf;
  }

  isNumber(value?: string | number): boolean {
   return ((value != null) &&
           (value !== '') &&
           !isNaN(Number(value.toString())));
  }

  trim(str: string) {
    if (!str) {
      return null;
    }
    return str.replace(/\s/g, "");
  }

}

export default new StringUtil();
