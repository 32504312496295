import http from '../http-common';
import axios from 'axios';
import { Contract } from 'src/models/contract';
import { ContractDistribuitionResponse } from 'src/models/contract.distribuition.response';
import { ContractResponse } from 'src/models/contract.response';
import { ContractUpdate } from 'src/models/contract.update';

class ContractService {
  async create(contract: Contract) {
    return http.post('/api/contracts/createContract', contract);
  }

  async confirmContract(contract: ContractResponse) {
    return http.put(
      '/api/contracts/confirmContract',
      JSON.stringify({ CodContrato: contract.codigo_contrato })
    );
  }

  async update(contract: ContractUpdate) {
    return http.put('/api/contracts/updateContract', contract);
  }

  async findById(id: number) {
    return http.get<Array<ContractResponse>>(`/api/contracts/contract/${id}`);
  }

  async findDistribuitionByContract(contractId: string) {
    return http.get<Array<ContractDistribuitionResponse>>(
      `/api/contracts/distributions?CodContrato=${contractId}`
    );
  }

  async findByCustomerId(customerId: string, status: string) {
    return http.get<Array<ContractResponse>>(
      `/api/contracts/getConsumerContracts?cod_consumer=${customerId}&situacao_contrato=${status}`
    );
  }

  async findPaymentDate(productCode: string, contributionDate: string) {
    return http.get<Array<any>>(
      `/api/contracts/calculateDt?productCode=${productCode}&dtAporte=${contributionDate}`
    );
  }

  async findByParams(
    operatorCode: string,
    operatorClassCode: string,
    team: string,
    costCenter: string,
    pageNumber: number,
    pageSize: number,
    name?: string
  ) {
    return http.get(
      `/api/contracts?cod_operador=${operatorCode}&cod_classe_operador=${operatorClassCode}&equipe=${team}&centro_custo=${costCenter}&pageSize=${pageSize}&pageNumber=${pageNumber}&offset=${
        pageNumber * pageSize
      }&Nome=${name}`
    );
  }

  async rejectContract(contract: ContractResponse) {
    return http.put(
      '/api/contracts/rejectContract',
      JSON.stringify({
        CodContrato: contract.codigo_contrato,
        SituacaoContrato: contract.situacao
      })
    );
  }

  async terminateConsig(data) {
    console.log('aqui');
    return http.put('/api/contracts/terminateConsig', data);
  }
  
}

export default new ContractService();
