import { ChangeOperatorPasswordRequest, Login, ResetPasswordRequest, ResetTokenRequest } from "src/models/login";
import http from "../http-common";
import storageUtil from '../util/storage.util';

class AuthenticationService {

  async login(login: Login) {
    return http.post("/api/users/login", JSON.stringify(login))
  }

  async resetPassword(body: ResetPasswordRequest) {
    return http.post("/api/users/reset-password", JSON.stringify(body))
  }

  async getTerm(cod_operador: string) {
    return http.get(`/api/users/getLgpdTerm?cod_operador=${cod_operador}`);
  }

  async confirmTerm(body: any) {
    return http.put('/api/users/assingLgpdTerm', JSON.stringify(body));
  }

  async requestToken(body: ResetTokenRequest) {
    return http.post("/api/users/request-reset-password", JSON.stringify(body))
  }

  async loginConsumer(login: Login) {
    return http.post("/api/users/loginConsumer", JSON.stringify(login))
  }

  async changeOperatorPassword(body: ChangeOperatorPasswordRequest) {
    return http.post("/api/users/reset-operator-password", JSON.stringify(body))
  }

  getCurrentUser() {
    let currentUser =  storageUtil.getItem("loggedUser")
    return currentUser;
  }

  getStringItem(itemName: string) {
    return storageUtil.getStringItem(itemName)
  }

}

export default new AuthenticationService();
