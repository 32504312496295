import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { RouteObject } from 'react-router';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';
import DashboardOperator from './content/dashboards/operator';
import Autenticator from './components/Authenticator';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Pages

const Login = Loader(lazy(() => import('src/content/pages/Login')));

const LoginConsumer = Loader(
  lazy(() => import('src/content/pages/LoginConsumer'))
);

const ResetPassword = Loader(
  lazy(() => import('src/content/pages/ResetPassword'))
);

const ForgotPass = Loader(
  lazy(() => import('src/content/pages/ForgotPassword'))
);

const ChangePassword = Loader(
  lazy(() => import('src/content/pages/ChangePassword'))
);

const CustomerChangePassword = Loader(
  lazy(() => import('src/content/pages/CustomerChangePassword'))
);

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const DashboardCustomer = Loader(
  lazy(() => import('src/content/dashboards/customer'))
);

// Applications

const Messenger = Loader(
  lazy(() => import('src/content/applications/Messenger'))
);

const UserProfile = Loader(
  lazy(() => import('src/content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('src/content/applications/Users/settings'))
);

// Components

const Buttons = Loader(
  lazy(() => import('src/content/pages/Components/Buttons'))
);
const Modals = Loader(
  lazy(() => import('src/content/pages/Components/Modals'))
);
const Accordions = Loader(
  lazy(() => import('src/content/pages/Components/Accordions'))
);
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(
  lazy(() => import('src/content/pages/Components/Badges'))
);
const Tooltips = Loader(
  lazy(() => import('src/content/pages/Components/Tooltips'))
);
const Avatars = Loader(
  lazy(() => import('src/content/pages/Components/Avatars'))
);
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Pages
const Customers = Loader(lazy(() => import('src/content/pages/Customers')));

const CustomersEdit = Loader(
  lazy(() => import('src/content/pages/Customers/edit'))
);
const Proposals = Loader(lazy(() => import('src/content/pages/Proposals')));
const ProposalsEdit = Loader(
  lazy(() => import('src/content/pages/Proposals/edit'))
);

const CustomerConfirm = Loader(
  lazy(() => import('src/content/pages/CustomerConfirm/edit'))
);

const ConsumerContracts = Loader(
  lazy(() => import('src/content/pages/ConsumerContracts'))
);

// Status

const Status404 = Loader(
  lazy(() => import('src/content/pages/Status/Status404'))
);
const Status500 = Loader(
  lazy(() => import('src/content/pages/Status/Status500'))
);
const StatusComingSoon = Loader(
  lazy(() => import('src/content/pages/Status/ComingSoon'))
);
const StatusMaintenance = Loader(
  lazy(() => import('src/content/pages/Status/Maintenance'))
);
const ResetPasswordSucess = Loader(
  lazy(() => import('src/content/pages/ResetPasswordSucess'))
);

const ForgotPasswordSucess = Loader(
  lazy(() => import('src/content/pages/ForgotPasswordSucess'))
);

const routes: RouteObject[] = [
  {
    path: '',
    element: <BaseLayout />,
    children: [
      {
        path: '/login-operator',
        element: <Login />
      },
      {
        path: '/login-consumer',
        element: <LoginConsumer />
      },
      {
        path: '/reset-password/:token',
        element: <ResetPassword />
      },
      {
        path: '/reset-password-success',
        element: <ResetPasswordSucess />
      },
      {
        path: '/forgot-password-success',
        element: <ForgotPasswordSucess />
      },
      {
        path: 'overview',
        element: <Navigate to="/" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forgotpass',
        element: <ForgotPass />
      },
      {
        path: 'pages',
        element: (
          <Autenticator>
            <SidebarLayout />
          </Autenticator>
        ),
        children: [
          {
            path: 'customers',
            element: <Customers />
          },
          {
            path: 'customers/edit',
            element: <CustomersEdit />
          },
          {
            path: 'customers/edit/:id',
            element: <CustomersEdit />
          },
          {
            path: 'proposals',
            element: <Proposals />
          },
          {
            path: 'proposals/edit',
            element: <ProposalsEdit />
          },
          {
            path: 'proposals/edit/:id',
            element: <ProposalsEdit />
          },
          {
            path: 'consumer-contracts',
            element: <ConsumerContracts />
          },
          {
            path: 'customers-confirm/edit/:id',
            element: <CustomerConfirm />
          }
        ]
      },
      {
        path: 'status',
        children: [
          {
            path: '',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: 'dashboards',
    element: (
      <Autenticator>
        <SidebarLayout />
      </Autenticator>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="crypto" replace />
      },
      {
        path: 'crypto',
        element: <Crypto />
      },
      {
        path: 'customer',
        element: <DashboardCustomer />
      },
      {
        path: 'operator',
        element: <DashboardOperator />
      },
      {
        path: 'messenger',
        element: <Messenger />
      }
    ]
  },
  {
    path: 'management',
    element: (
      <Autenticator>
        <SidebarLayout />
      </Autenticator>
    ),
    children: [
      {
        path: 'profile',
        children: [
          {
            path: '',
            element: <Navigate to="details" replace />
          },
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          },
          {
            path: 'change-pass',
            element: <ChangePassword />
          },
          {
            path: 'customer-change-pass',
            element: <CustomerChangePassword />
          }
        ]
      }
    ]
  },
  {
    path: '/components',
    element: (
        <SidebarLayout />
    ),
    children: [
      {
        path: '',
        element: <Navigate to="buttons" replace />
      },
      {
        path: 'buttons',
        element: <Buttons />
      },
      {
        path: 'modals',
        element: <Modals />
      },
      {
        path: 'accordions',
        element: <Accordions />
      },
      {
        path: 'tabs',
        element: <Tabs />
      },
      {
        path: 'badges',
        element: <Badges />
      },
      {
        path: 'tooltips',
        element: <Tooltips />
      },
      {
        path: 'avatars',
        element: <Avatars />
      },
      {
        path: 'cards',
        element: <Cards />
      },
      {
        path: 'forms',
        element: <Forms />
      }
    ]
  }
];

export default routes;
