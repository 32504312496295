import { Navigate } from 'react-router';
import storageUtil from 'src/util/storage.util';

function Autenticator(props) {
  const loggedUser = storageUtil.getItem('loggedUser');
  return (!loggedUser?.username || !loggedUser?.cod_emp_op) && (!loggedUser?.CA05_CODCLI) ? (
    <Navigate to={'/login-consumer'}></Navigate>
  ) : (
    props.children
  );
}

export default Autenticator;
