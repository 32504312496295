import {
  Button,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  Avatar,
  alpha,
  Tooltip,
  CardActionArea,
  styled
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { useEffect, useState } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import authenticationService from 'src/services/authentication.service';
import contractService from 'src/services/contract.service';
import CurrencyUtil from 'src/util/currency.util';
import stringUtil from 'src/util/string.util';
import customerService from 'src/services/customer.service';

interface ProductBalance {
  productName: string;
  amount: Number;
  percentage: Number;
}


function Wallets() {

  const [totalCustomers, setTotalCustomers] = useState<number | 0>(0); 
  const [totalContracts, setTotalContracts] = useState<number | 0>(0); 
  const [totalBalance, setTotalBalance] = useState<string | ''>(''); 

  const findContractsByOperator = async () => {
    let loggedUser = authenticationService.getCurrentUser();
    try {
      await contractService.findByParams(loggedUser['cod_operador'],
                                          loggedUser['cod_classe_operador'],
                                          loggedUser['equipe'],
                                          loggedUser['centro_custo'], 0, 3)
        .then(async result => {
          if (result?.data) {
            let listContracts = result?.data as Array<any>;
            let total = await getTotalBalance(listContracts)
            setTotalBalance(CurrencyUtil.formatNewCurrency(total.toFixed(2)))
            setTotalContracts(listContracts.length)

          }
        }, err => {

        }
      );
      
    } catch (err) {
      
    }
    
  };

  const findAllCustomers  = async () => {
    let loggedUser = authenticationService.getCurrentUser();
    await customerService.findByParams({cod_operador: loggedUser['cod_operador'], 
                                        cod_classe_operador: loggedUser['cod_classe_operador']}, 10, 1)
    .then(
      response => {
        if (response?.data) {
          let listCustomers = response?.data as Array<any>;
          setTotalCustomers(listCustomers.length)
        }
        
      }, err => {
        
      }
    )
  }

  function getTotalBalance(listContracts): Number {
    let totalBalance = Number(0);
    listContracts.forEach((contract) => {
      if (stringUtil.isNumber(contract['valor_contrato'])) {
        totalBalance += Number(CurrencyUtil.parseAmount(contract['valor_contrato']));
      }
    });
    return Number(totalBalance.toString());
  }
  
  useEffect(() => {
    findContractsByOperator();
    findAllCustomers();
  }, []);

  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent>
              <ArticleIcon style={{ fill: '#195439' }}/>
              <Typography variant="h5" noWrap>
                Contratos
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  {totalContracts}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={3} item>
          <Card
            sx={{
              px: 1
            }}
          >
            <CardContent>
              <PersonIcon style={{ fill: '#195439' }}/>
              <Typography variant="h5" noWrap>
                Clientes
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  {totalCustomers}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} sm={6} md={3} item>
          <Card
          style = {{width: 350}} 
            sx={{
              px: 1,
              
            }}
          >
            <CardContent>
              <AttachMoneyIcon style={{ fill: '#195439' }}/>
              <Typography variant="h5" noWrap>
                Valor total em contratos
              </Typography>
              <Box
                sx={{
                  pt: 3
                }}
              >
                <Typography variant="h3" gutterBottom noWrap>
                  {totalBalance}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

      </Grid>
    </>
  );
}

export default Wallets;
