
class StorageUtil {

  setItem(itemName: string, item: any) {
    sessionStorage.setItem(itemName, JSON.stringify(item))
  }

  setStringItem(itemName: string, item: any) {
    sessionStorage.setItem(itemName, item)
  }

  getItem(itemName: string) {
    return JSON.parse(sessionStorage.getItem(itemName));
  }

  getStringItem(itemName: string) {
    return sessionStorage.getItem(itemName);
  }

}

export default new StorageUtil();
