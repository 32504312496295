import axios from "axios";

const customFetch = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
  }
  
});

customFetch.interceptors.request.use(
  async (config) => {
    const token = await getTokenFromLocalStorage();
    if (token) {
      config.headers["Authorization"] = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const refreshToken = async () => {
  try {
    const resp = await customFetch.post("/authenticate", {username: "GTT_user", password: "7P#Ez5nO22NHTGts5BZatmTg$h9&?r9T"});
    //const resp = await customFetch.post("/authenticate", {username: "MateusVeigaSantos", password: "senha_padrao"});
    return resp.data;
  } catch (e) {
    
  }
};

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if ((error.response.status === 403 || error.response.status === 401) && !originalRequest._retry ) {
      originalRequest._retry = true;

      const resp = await refreshToken();
    
      const accessToken = resp['accessToken'];
    
      localStorage.setItem('token', accessToken); 
      customFetch.defaults.headers.common["Authorization"] = `${accessToken}`;
      return customFetch(originalRequest);
    }
    return Promise.reject(error);
  }
);

const getTokenFromLocalStorage = async () => {
  return localStorage.getItem("token")
}

export default customFetch;
