import { CustomerResponse } from "src/models/customer.response";
import { CustomerRequest } from "src/models/customer.request";
import http from "../http-common";
import { CustomerSearch } from "src/models/search";
import { ChangeCustomerPasswordRequest, FirstLoginEmailRequest } from "src/models/login";


class CustomerService {

  async create(customer: CustomerRequest) {
    return http.post("/api/consumers/createConsumer", customer);
  }

  async createLead(customer: CustomerRequest) {
    return http.post("/api/consumers/createLead", customer);
  }

  async confirmLead(customer: CustomerRequest) {
    return http.put("/api/consumers/confirmLead", customer);
  }

  async update(customer: CustomerRequest) {
    return http.put("/api/consumers/updateConsumer", customer);
  }

  async updatePassword(changePass: ChangeCustomerPasswordRequest) {
    return http.post("/api/users/reset-consumer-password", changePass);
  }

  async sendEmailFirstLogin(request: FirstLoginEmailRequest) {
    return http.post("/api/contracts/firstLogin", request);
  }

  async updateLead(customer: CustomerRequest) {
    return http.put("/api/consumers/updateLead", customer);
  }

  async findByParams(customerSearch: CustomerSearch, pageNumber?: number, pageSize?: number, name?: string ) {
    return http.get<Array<CustomerResponse>>(`/api/consumers?cod_operador=${customerSearch.cod_operador}${customerSearch.cod_classe_operador ? `&cod_classe_operador=${customerSearch.cod_classe_operador || ""}`: '' }&pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""}&offset=${(pageNumber * pageSize) || ""}&Nome=${name||""}`);
  }

  async findById(id: string) {
    return http.get<Array<CustomerResponse>>(`/api/consumers/consumer/${id}`);
  }

}

export default new CustomerService();