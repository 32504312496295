
class CurrencyUtil {

  static parseCurrency(currency: string) {
    return Number(currency.replace('.', '').replace(',', '.'))
  }

  static formatAmount( number ) {
        
    if (!number) {
      return null;
    }
    number = number.toString().replace( /[^0-9]/g, '' );

    if( number.length == 0 ) number = "0.00";
    else if( number.length == 1 ) number = "0.0" + number;
    else if( number.length == 2 ) number = "0." + number;
    else number = number.substring( 0, number.length - 2 ) + '.' + number.substring( number.length - 2, number.length );

    number = new Number( number );
    number = number.toFixed( 2 );   

    number = number.replace( /\./g, ',' );

    let x = number.split( ',' );
    let x1 = x[0];
    let x2 = x.length > 1 ? ',' + x[1] : '';
    let result = this.formatAmountNoDecimals( x1 ) + x2;
    return result;
  }

  static getMoney( str ) {
    return parseInt( str.replace(/[\D]+/g,'') );
}

static formatRealCurrency( int ) {
  var tmp = int+'';
  tmp = tmp.replace(/([0-9]{2})$/g, ",$1");
  if( tmp.length > 6 )
          tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");

  return tmp;
}

  static formatNewCurrency(amount) {
    if (!amount) {
        return null;
    }
    var v = amount.replace(/\D/g,'');
    v = (v/100).toFixed(2) + '';
    v = v.replace(".", ",");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    return v;
}

  static formatAmountNoDecimals( number ) {
    var rgx = /(\d+)(\d{3})/;
    while( rgx.test( number ) ) {
        number = number.replace( rgx, '$1' + '.' + '$2' );
    }
    return number;
  }

  static parseAmount(price) {
    if (!price) {
        return null;
    }
    let formattedPrice = price.toString().replace('.', '');
    formattedPrice = formattedPrice.toString().replace('.', '');
    formattedPrice = formattedPrice.toString().replace(',', '.');
    return formattedPrice;
  }

  static parseAmountSimple(price) {
    if (!price) {
        return null;
    }
    let formattedPrice = price.toString().replace(',', '.');
    return formattedPrice;
  }

}

export default CurrencyUtil;
